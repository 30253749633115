<template>
  <div>
      Processing...
  </div>
</template>

<script>
import { auth } from '../schmucklicloud'
import { Notifications } from '../notification';
export default {
    async mounted() {
        var token = window.location.href.split("?")[1].replace("token=", "");
        var response = await auth.activateUser(token);

        if (response.isOK) {
            Notifications.show(this.$t("activate.notification.user_activated"));
        } else {
            Notifications.show(response.message);
        }
        this.$router.replace("/login");
    }
}
</script>

<style>

</style>